import {useQuery, useMutation, queryClient} from "../hooks/react-query"

import {
  getWorkspaceCategories,
  getCategoryTypes,
  updateCategoryType,
  updateCategoryTypeIn,
} from "../lib/smartworks-api/category"

import {getWorkspace} from "./auth"

const CATEGORY_QUERY_KEY = id => ["category", {workspace: id}]
const CATEGORY_TYPES_QUERY_KEY = (id, category) => [
  "category-types",
  {workspace: id, category},
]

/**
 * @template TData, TVariables, TContext
 * @typedef {import('../hooks/react-query').UseMutationOptions<TData, FetchError, TVariables, TContext>} UseMutationOptions
 */

/**
 * @param {import('../hooks/react-query').UseQueryOptions<WorkspaceCategoryInfo[], FetchError} config
 */
export function useCategories(config = {}) {
  return useQuery({
    queryKey: CATEGORY_QUERY_KEY(getWorkspace()),
    queryFn: () => getWorkspaceCategories({workspace: getWorkspace()}),
    ...config,
  })
}

/**
 * @param {Category} category
 * @param {import('../hooks/react-query').UseQueryOptions<CategoryTypeSetting[], FetchError} config
 */
export function useCategoryTypes(category, config = {}) {
  return useQuery({
    queryKey: CATEGORY_TYPES_QUERY_KEY(getWorkspace(), category),
    queryFn: () => getCategoryTypes({workspace: getWorkspace(), category}),
    enabled: !!category,
    ...config,
  })
}

/**
 * @template TResult,TVariables, TSnapshot
 * @param {UseMutationOptions<TResult, TVariables, TSnapshot>} config
 */
export function useUpdateCategoryType(config = {}) {
  const {onSuccess, ...restConfig} = config
  return useMutation(updateCategoryType, {
    ...restConfig,
    errorMessage: "Oops, something went wrong. Please try again later.",
    successMessage: "Setting is updated successfully.",
    onSuccess: async (data, variables, context) => {
      const {categoryTypeSetting} = variables
      const cacheKey = CATEGORY_TYPES_QUERY_KEY(
        categoryTypeSetting.workspaceId,
        categoryTypeSetting.category,
      )

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(cacheKey)

      // update the query cache with the updated data
      queryClient.setQueryData(cacheKey, oriData =>
        updateCategoryTypeIn(oriData, categoryTypeSetting),
      )

      // Always refetch after success:
      queryClient.invalidateQueries(cacheKey)

      onSuccess && onSuccess(data, variables, context)
    },
  })
}

/**
 * @typedef {import('../domains/category').Category} Category
 * @typedef {import('../domains/category').WorkspaceCategoryInfo} WorkspaceCategoryInfo
 * @typedef {import('../domains/category').CategoryTypeSetting} CategoryTypeSetting
 * @typedef {import('../lib/fetch').FetchError} FetchError
 */
