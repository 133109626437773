import {useRef} from "react"
import throttle from "lodash.throttle"

export function useScrollEnd(onScrollEnd, time = 200) {
  /** @type {React.LegacyRef<HTMLDivElement>} */
  const scrollableRef = useRef()

  const onScrollHandler = () => {
    const scrollTop = scrollableRef.current.scrollTop
    const scrollHeight = scrollableRef.current.scrollHeight
    const innerHeight = scrollableRef.current.clientHeight

    if (scrollTop + innerHeight + 250 >= scrollHeight) {
      onScrollEnd && onScrollEnd()
    }
  }

  return {
    ref: scrollableRef,
    onScroll: throttle(onScrollHandler, time),
  }
}
