import * as React from "react"
import classNames from "classnames"

import ContentPlaceholder from "./content-placeholder"

import {useScrollEnd} from "../../hooks/intersection-observer"
import {range} from "../../utils/range"

/**
 * @typedef {object} TableOwnProps
 * @property {boolean=} full
 * @property {()=>void=} onScrollEnd
 *
 * @typedef {React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>} RawTableProps
 * @typedef {RawTableProps & TableOwnProps} TableProps
 */

/**
 * @type {React.FunctionComponent<TableProps>}
 */
export const Table = ({className, full = false, ...props}) => {
  if (full) {
    return <TableFull className={className} {...props} />
  }
  return (
    <div className="sw-table-wrapper">
      <table className={classNames("sw-table", className)} {...props} />
    </div>
  )
}

/**
 * @type {React.FunctionComponent<TableProps>}
 */
export const TableFull = ({className, onScrollEnd, ...props}) => {
  const scrollProps = useScrollEnd(onScrollEnd)

  return (
    <div {...scrollProps} className="sw-table-wrapper full">
      <table
        className={classNames("sw-table sw-table-full", className)}
        {...props}
      />
    </div>
  )
}

export const TablePlaceholder = ({num}) => {
  return (
    <Table className="sw-table skeleton">
      <thead>
        <tr>
          <th className="fixed-width"> </th>
          <th className="flex-grow-1 flex-b-250">
            <ContentPlaceholder />
          </th>
          <th className="flex-grow-1 flex-b-200">
            <ContentPlaceholder />
          </th>
          <th className="flex-grow-1 flex-b-200">
            <ContentPlaceholder />
          </th>
          <th className="flex-grow-1 flex-b-200">
            <ContentPlaceholder />
          </th>
          <th className="fixed-width"> </th>
        </tr>
      </thead>
      <tbody>
        {range(num % 11).map(id => (
          <tr className="skeleton-vertical" key={id}>
            <td className="fixed-width"> </td>
            <td className="flex-grow-1 flex-b-250">
              <ContentPlaceholder baseWidthPercentage={50} />
            </td>
            <td className="flex-grow-1 flex-b-200">
              <ContentPlaceholder baseWidthPercentage={50} />
            </td>
            <td className="flex-grow-1 flex-b-200">
              <ContentPlaceholder baseWidthPercentage={50} />
            </td>
            <td className="flex-grow-1 flex-b-200">
              <ContentPlaceholder baseWidthPercentage={50} />
            </td>
            <td className="fixed-width"> </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
