import {isNull} from "../../utils/is-null"
import authoredFetch from "../aws/amplify-fetch"

import {DEFAULT_BASEURL, CATEGORY_ENDPOINT} from "./endpoints"

/**
 * @param {WorkspaceCategoryDTO[]} categoriesDTO
 * @returns {WorkspaceCategoryInfo[]}
 */
const categoryDTOMapper = categoriesDTO => {
  return categoriesDTO.map(categoryDTO => ({
    category: categoryDTO.category,
    name: categoryDTO.name,
    workspaceId: categoryDTO.workspaceId,
  }))
}

/**
 * @param {{
 *   workspace: string
 * }} param0
 * @returns {Promise<WorkspaceCategoryInfo[]>}
 */
export async function getWorkspaceCategories({workspace}) {
  return authoredFetch(CATEGORY_ENDPOINT + "/" + workspace, {
    baseURL: DEFAULT_BASEURL,
    method: "GET",
  }).then(({data}) => categoryDTOMapper(data))
}

/**
 * @param {CategoryTypeSettingDTO[]} categoryTypesDTO
 * @returns {CategoryTypeSetting[]}
 */
const categoryTypeDTOMapper = (categoryTypesDTO, workspace) => {
  return categoryTypesDTO
    .map((categoryTypeDTO, index) => ({
      workspaceId: categoryTypeDTO.workspaceId || workspace,
      category: categoryTypeDTO.category,
      categoryType: categoryTypeDTO.categoryType,
      name: categoryTypeDTO.name,
      restrictedDuration: categoryTypeDTO.restrictedDuration,
      accessRestriction: categoryTypeDTO.accessRestricted,
      trigger: categoryTypeDTO.trigger && !!categoryTypeDTO.trigger,
      order: isNull(categoryTypeDTO.order) ? index : categoryTypeDTO.order,
    }))
    .sort((c1, c2) => c1.order - c2.order)
}

/**
 * @param {{
 *   workspace: string
 *   category: Category
 * }} param0
 * @returns {Promise<CategoryTypeSetting[]>}
 */
export async function getCategoryTypes({workspace, category}) {
  return authoredFetch(CATEGORY_ENDPOINT + "/" + workspace, {
    baseURL: DEFAULT_BASEURL,
    method: "GET",
    params: {
      category,
    },
  }).then(({data}) => categoryTypeDTOMapper(data, workspace))
}

/**
 * @param {CategoryTypeSetting[]} categoryTypes
 * @param {CategoryTypeSetting} updatedCategoryType
 * @returns {CategoryTypeSetting[]}
 */
export function updateCategoryTypeIn(categoryTypes, updatedCategoryType) {
  return categoryTypes.map(categoryType => {
    if (categoryType.categoryType !== updatedCategoryType.categoryType) {
      return categoryType
    }

    return {
      ...categoryType,
      ...updatedCategoryType,
    }
  })
}

/**
 * @param {{
 *   categoryTypeSetting: CategoryTypeSetting
 * }} param0
 * @returns {Promise<CategoryTypeSetting[]>}
 */
export async function updateCategoryType({categoryTypeSetting}) {
  const data = {
    category: categoryTypeSetting.category,
    categoryType: categoryTypeSetting.categoryType,
    duration: categoryTypeSetting.restrictedDuration || 1,
  }
  if (!isNull(categoryTypeSetting.trigger)) {
    data.trigger = categoryTypeSetting.trigger ? 1 : 0
  }

  return authoredFetch(
    CATEGORY_ENDPOINT + "/" + categoryTypeSetting.workspaceId,
    {
      baseURL: DEFAULT_BASEURL,
      method: "PUT",
      data,
    },
  ).then(({data}) => data)
}

/**
 * @typedef {import('../../domains/category').Category} Category
 * @typedef {import('../../domains/category').CategoryTypeSettingDTO} CategoryTypeSettingDTO
 * @typedef {import('../../domains/category').CategoryTypeSetting} CategoryTypeSetting
 * @typedef {import('../../domains/category').WorkspaceCategoryDTO} WorkspaceCategoryDTO
 * @typedef {import('../../domains/category').WorkspaceCategoryInfo} WorkspaceCategoryInfo
 */
