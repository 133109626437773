import {useQuery, useMutation, queryClient} from "../hooks/react-query"

import {
  getWorkspaceSettings,
  updateDTSurveySettings,
} from "../lib/smartworks-api/workspace"

import {getWorkspace} from "./auth"

const WORKSPACE_QUERY_KEY = id => ["workspace-settings", {workspace: id}]

/**
 * @template TData, TVariables, TContext
 * @typedef {import('../hooks/react-query').UseMutationOptions<TData, FetchError, TVariables, TContext>} UseMutationOptions
 */

/**
 * @param {import('../hooks/react-query').UseQueryOptions<WorkspaceSettings, FetchError} config
 */
export function useWorkspaceSettings(config = {}) {
  return useQuery({
    queryKey: WORKSPACE_QUERY_KEY(getWorkspace()),
    queryFn: () => getWorkspaceSettings({workspace: getWorkspace()}),
    ...config,
  })
}

/**
 * @template TResult, TSnapshot
 * @param {UseMutationOptions<TResult, UpdateDTSurveySettingsParams, TSnapshot>} config
 */
export function useUpdateDTSurveySettings(config = {}) {
  const {onSuccess, ...restConfig} = config
  return useMutation(
    args =>
      updateDTSurveySettings({
        workspace: getWorkspace(),
        ...args,
      }),
    {
      ...restConfig,
      errorMessage: error =>
        error?.response?.status === 500
          ? "Something went wrong. Unable to update setting"
          : undefined,
      successMessage: "Setting is updated successfully.",
      onSuccess: () => {
        queryClient.invalidateQueries(WORKSPACE_QUERY_KEY(getWorkspace()))
        onSuccess && onSuccess()
      },
    },
  )
}

/** @typedef {import('../lib/smartworks-api/workspace').WorkspaceSettings} WorkspaceSettings */
/** @typedef {import('../lib/smartworks-api/workspace').UpdateDTSurveySettingsParams} UpdateDTSurveySettingsParams */
/** @typedef {import('../lib/fetch').FetchError} FetchError */
