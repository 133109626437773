import * as React from "react"

import {DropDown} from "../components/base/drop-down"

import {useUpdateUserStatus} from "../services/user"

// for styles, please check app-employee.scss

/**
 * @param {{
 *   user: UserRecord,
 *   category: Category,
 *   categoryTypes: CategoryTypeSetting[],
 *   pageIndex?: number,
 *   className?: string,
 *   defaultTitle?: string,
 * }} param0
 */
const CategoryDropDown = ({
  user,
  category,
  categoryTypes,
  className = "",
  defaultTitle = "Not Available",
}) => {
  const {mutate: updateUserStatus, ...updateState} = useUpdateUserStatus()

  const categoryTypesOptions = updateState.isLoading
    ? [{id: 0, title: "updating..."}]
    : categoryTypes.map(categoryType => ({
        id: categoryType.categoryType,
        title: categoryType.name,
        selectedClass: categoryType.categoryType,
      }))

  const selectedId = updateState.isLoading ? 0 : user?.userStatusInfo?.status

  return (
    <DropDown
      options={categoryTypesOptions}
      selectedId={selectedId}
      className={`category-dropdown-button ${category} ${className}`}
      disabled={updateState.isLoading}
      defaultTitle={defaultTitle}
      onOptionSelect={option => {
        if (!updateState.isLoading && option.id && option.id !== selectedId) {
          updateUserStatus({
            user: user.userId,
            category,
            categoryType: option.id,
          })
        }
      }}
    />
  )
}

export default CategoryDropDown

/**
 * @typedef {import('../domains/user').UserRecord} UserRecord
 * @typedef {import('../domains/category').CategoryTypeSetting} CategoryTypeSetting
 * @typedef {import('../domains/category').Category} Category
 */
