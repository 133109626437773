import * as React from "react"

// import images: icon, placeholder, ...
import BTN_UPLOAD from "../images/btn-upload.svg"
import IMG_EMPLOYEE_EMPTY from "../images/img-employee-empty.svg"

// import components
import {Spacing} from "../components/base/spacing"

const EmptyPlaceholder = () => {
  return (
    <div className="flex-1 column-center">
      <img src={IMG_EMPLOYEE_EMPTY} alt={IMG_EMPLOYEE_EMPTY} />
      <Spacing spacing="xxl-3" />
      <h1 className="text-40 text-bold">
        Tap
        <span
          style={{
            margin: "0 12px",
          }}>
          <img src={BTN_UPLOAD} alt={BTN_UPLOAD} />
        </span>
        to upload Employees
      </h1>
      <p>
        Import your employee list by uploading file or adding them manually.
      </p>
    </div>
  )
}

export default EmptyPlaceholder
