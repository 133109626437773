import authoredFetch from "../aws/amplify-fetch"

import {DEFAULT_BASEURL, WORKSPACE_ENDPOINT} from "./endpoints"

/**
 * @param {{
 *   workspace: string
 * }} param0
 * @returns {Promise<WorkspaceSettings>}
 */
export async function getWorkspaceSettings({workspace}) {
  return authoredFetch(WORKSPACE_ENDPOINT + "/v1/" + workspace, {
    baseURL: DEFAULT_BASEURL,
    method: "GET",
  }).then(({data}) => workspaceSettingsDTOMapper(data))
}

/**
 * @param {WorkspaceSettingsDTO} workspaceSettingsDTO
 * @returns {WorkspaceSettings}
 */
const workspaceSettingsDTOMapper = workspaceSettingsDTO => {
  return {
    dailySurvey: {
      apiKey: workspaceSettingsDTO?.dailySurvey?.key,
      surveyId: workspaceSettingsDTO?.dailySurvey?.survey_id,
    },
    backToWorkSurvey: {
      apiKey: workspaceSettingsDTO?.backToWorkSurvey?.key,
      surveyId: workspaceSettingsDTO?.backToWorkSurvey?.survey_id,
    },
  }
}

/**
 * @typedef {object} UpdateWorkspaceParams
 * @property {string} surveyId
 * @property {string} apiKey
 * @property {string} workspace
 */

/**
 * @param {UpdateDTSurveySettingsParams} param0
 */
export async function updateDTSurveySettings({
  surveyId,
  apiKey,
  workspace,
  surveyType,
}) {
  return authoredFetch(WORKSPACE_ENDPOINT + "/v1/" + workspace, {
    baseURL: DEFAULT_BASEURL,
    method: "PUT",
    data: {
      [surveyType]: {
        api_key: apiKey,
        survey_id: surveyId,
      },
    },
  }).then(({data}) => data)
}

/**
 * @typedef {import('../../domains/workspace-settings').UpdateDTSurveySettingsParams} UpdateDTSurveySettingsParams
 * @typedef {import('../../domains/workspace-settings').WorkspaceSettings} WorkspaceSettings
 * @typedef {import('../../domains/workspace-settings').WorkspaceSettingsDTO} WorkspaceSettingsDTO
 */
