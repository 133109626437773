export type Category = "eligibilityStatus" | "workStatus"

export const CategoryString = {
  eligibilityStatus: "eligibilityStatus",
  workStatus: "workStatus",
}

export type WorkspaceCategoryDTO = {
  category: Category
  name: string
  workspaceId: string
}

export type CategoryTypeSettingDTO = {
  accessRestricted: boolean
  category: Category
  categoryType: string
  name: string
  order?: number
  restrictedDuration: number
  trigger?: 0 | 1
  workspaceId: string
}

export interface WorkspaceCategoryInfo {
  category: Category
  name: string
  workspaceId: string
}

export interface CategoryTypeSetting {
  workspaceId: string
  category: Category
  categoryType: string
  name: string
  restrictedDuration: number
  accessRestriction: boolean
  trigger?: boolean
  order?: number
}
