export interface UsersInfoPerPage {
  ids: string[]
  dataMap: {[id: string]: UserRecord}
  customKeys: string[]
  lastKey: LastKeyStatus
  count: number
}

export type UsersStatus = Omit<UsersInfoPerPage, "lastKey">

export enum UserRecordStatus {
  Allow = 0,
  XX = 1,
  Restrict = 2,
}

export type UserDTO = {
  userInfo: any
  userStatusInfo: UserStatusInfo
}
export type UsersResponseDTO = {
  users: UserDTO[]
  count?: number
  lastKey?: any
}

export interface UserRecord {
  userId: string
  userInfo: UserInfo
  userStatusInfo: UserStatusInfo
}

export interface UserInfo {
  account_type: "normal" | "admin"
  user_id: string
  workspace_id: string
  email: string
  name: string
  account_status: number
  status: UserRecordStatus
  time_zone: string
  last_modified?: number
  last_survey_modified?: number
  show_alert?: boolean
}

export interface UserStatusInfo {
  status: "n/a" | string
  category?: string
  categoryType?: string
  userId: string
  from?: keyof typeof UpdateUserStatusRole
  updateTime?: number
}

export interface LastKeyStatus {
  userId: string
  userName: string
  PK?: string
  SK?: string
  WORKSPACE_ID?: string
}

export const PRESERVED_USER_STATUS_KEYS = [
  "account_status",
  "account_type",
  "status",
  "user_id",
  "workspace_id",
  "last_modified",
  "last_survey_modified",
  "time_zone",
]

export const PRESERVED_USER_PROFILE_KEYS_A = ["employee id", "name", "email"]
export const PRESERVED_USER_PROFILE_KEYS_B = [
  "phone number",
  "work location",
  "team",
]

export const ACCOUNT_TYPES = {
  admin: "admin",
  normal: "normal",
  survey: "survey",
}

export const UpdateUserStatusRole = {
  admin: "admin",
  user: "user",
  ct: "ct",
}

export const USER_STATUS_DEFAULT_VALUE = "n/a"

export const ACCOUNT_STATUS_NOT_ACCEPTED = -1

export const USER_STATUS_CODE = {
  Allow: 0,
  Restrict: 2,
}
export const USER_STATUS = {
  0: "Allow",
  2: "Restrict",
}

export interface UpdateUserStatusParam {
  user: string
  category: string
  categoryType: string
  from: keyof typeof UpdateUserStatusRole
}

export interface GetEligibleUsersRawParam {
  workspace: string
  limit: number
  q?: "count" | string
  categoryType?: string
  userId?: string
}

export interface GetUsersParams {
  workspace: string
  limit: number
  lastKey?: any
}

export interface GetUsersStatusParams {
  category: string
  users: string[] // array of users' email
}

export interface GetUserStatusParams {
  category: string
  user: string // array of users' email
}

export interface GetEligibleUsersCountByCategoryTypeParam {
  workspace: string
  categoryType?: string
  // userId?: string
}

export function isUserStatusExpired(user: UserRecord): boolean {
  return user.userStatusInfo.status === USER_STATUS_DEFAULT_VALUE
}

export function isUserWithCovid19(user: UserRecord): boolean {
  return (
    user.userStatusInfo.status === "COVID19Positive" ||
    user.userStatusInfo.status === "exposedToCOVID19"
  )
}

export function isUserExposedToCovid19(user: UserRecord): boolean {
  return user.userStatusInfo.status === "exposedToCOVID19"
}

export function isUserCovid19Positive(user: UserRecord): boolean {
  return user.userStatusInfo.status === "COVID19Positive"
}
