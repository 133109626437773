import * as React from "react"

import {Modal, ModalHeader, ModalBody} from "./base/modal"

/**@typedef {import('./base/modal').ModalProps} ModalProps */
/**
 * @type {React.FunctionComponent<ModalProps>}
 * @param {ModalProps} props
 */
const SignUpHSForm = props => {
  const [iframeKey, setIframeKey] = React.useState(0)

  React.useEffect(() => {
    if (props.show) {
      setIframeKey(s => s + 1)
    }
  }, [props.show])

  return (
    <Modal {...props}>
      <ModalHeader>Sign Up</ModalHeader>
      <ModalBody>
        <iframe
          key={iframeKey}
          title="Sign up"
          style={{
            width: "100%",
            height: 500,
            borderRadius: 10,
            border: "solid 1.5px rgba(183, 189, 197, 0.3)",
            backgroundColor: "rgba(183, 189, 197, 0.03)",
          }}
          src={
            "https://share.hsforms.com/1eMOymjA0R1GueG_bDpo_Aw49ikq"
          }></iframe>
      </ModalBody>
    </Modal>
  )
}

export default SignUpHSForm
