import {navigate} from "gatsby"

import {
  useMutation,
  useInfiniteQueryWithCancel as useInfiniteQuery,
  useQueryWithCancel as useQuery,
  queryClient,
} from "../hooks/react-query"
import {
  importEmployee,
  invite,
  getUsers,
  getEligibleUsers,
  updateUserStatus,
  getRestricted,
  getEligibleCountByCategoryType,
  getUserStatus,
} from "../lib/smartworks-api/user"
import {CategoryString} from "../domains/category"
import {UpdateUserStatusRole} from "../domains/user"

import {getWorkspace} from "./auth"

const USERS_QUERY_KEY = ["users"]
const USER_STATUS_QUERY_KEY = ["user:status"]
const ELIGIBLE_USERS_QUERY_KEY = ["users:eligible"]
const ELIGIBLE_USERS_COUNT_QUERY_KEY = [...ELIGIBLE_USERS_QUERY_KEY, "count"]
export const RESTRICTED_QUERY_KEY = ["user:restricted"]

/**
 * @template TData, TVariables, TContext
 * @typedef {import('../hooks/react-query').UseMutationOptions<TData, FetchError, TVariables, TContext>} UseMutationOptions
 */

/**
 * @template TResult, TSnapshot
 * @param {UseMutationOptions<TResult, {file: File}, TSnapshot} config
 */
export function useImportEmployee(config = {}) {
  const {onSuccess, ...restConfig} = config
  return useMutation(
    args =>
      importEmployee({
        ...args,
        workspace: getWorkspace(),
      }),
    {
      ...restConfig,
      retry: 0,
      errorMessage:
        "Unable to import the Employee list, please check your format",
      successMessage:
        "Employee list is imported successfully, page will be refreshed soon.",

      onSuccess: (...args) => {
        invalidateUserRelatedData()
        onSuccess && onSuccess(...args)
      },
    },
  )
}

/**
 * @template TResult, TSnapshot
 * @param {UseMutationOptions<TResult, import('../lib/smartworks-api/user').InviteParams, TSnapshot} config
 */
export function useInvite(config = {}) {
  const {onSuccess, ...restConfig} = config
  return useMutation(
    args =>
      invite({
        workspace: getWorkspace(),
        ...args,
      }),
    {
      ...restConfig,
      retry: 0,
      errorMessage: error =>
        error?.response?.data?.errorMessage ||
        "Unable to invite user, please try again",
      successMessage:
        "Employee is invited successfully, page will be refreshed soon.",
      onSuccess: (...args) => {
        invalidateUserRelatedData()
        onSuccess && onSuccess(...args)
      },
    },
  )
}

/**
 * @param {import('../hooks/react-query').UseInfiniteQueryOptions<UsersInfoPerPage, FetchError, UsersInfoPerPage} config
 * @returns {import('../hooks/react-query').UseInfiniteQueryResult<UsersInfoPerPage, FetchError>}
 */
export function useUsers(config = {}) {
  return useInfiniteQuery(
    USERS_QUERY_KEY,
    ({pageParam: lastKey}, fetchOptions) => {
      return getUsers(
        {
          workspace: getWorkspace(),
          lastKey,
        },
        fetchOptions,
      )
    },
    {
      getNextPageParam: lastPage =>
        lastPage.lastKey?.userId && lastPage.lastKey?.userName
          ? lastPage.lastKey
          : undefined,
      ...config,
    },
  )
}

/**
 * @param {import('../hooks/react-query').UseQueryOptions<number, FetchError>} config
 * @returns {import('../hooks/react-query').UseQueryResult<number, FetchError>}
 */
export function useTotalUsersCount(config = {}) {
  const {data, ...queryState} = useUsers(config)

  return {
    ...queryState,
    data: data?.pages?.[0]?.count,
  }
}

/**
 * @param {import('../hooks/react-query').UseInfiniteQueryOptions<UsersInfoPerPage, FetchError, UsersInfoPerPage} config
 * @returns {import('../hooks/react-query').UseInfiniteQueryResult<UsersInfoPerPage, FetchError>}
 */
export function useEligibleUsers(config = {}) {
  return useInfiniteQuery(
    ELIGIBLE_USERS_QUERY_KEY,
    ({pageParam: lastKey}, fetchOptions) =>
      getEligibleUsers(
        {
          workspace: getWorkspace(),
          lastKey,
        },
        fetchOptions,
      ),
    {
      getNextPageParam: lastPage =>
        lastPage.lastKey?.userId ? lastPage.lastKey : undefined,
      ...config,
    },
  )
}

export async function cancelUserRelatedDataQuery() {
  return Promise.all([
    queryClient.cancelQueries(USERS_QUERY_KEY, {
      exact: true,
    }),
    queryClient.cancelQueries(RESTRICTED_QUERY_KEY, {
      exact: true,
    }),
    queryClient.cancelQueries(ELIGIBLE_USERS_QUERY_KEY, {}),
  ])
}

export function invalidateUserRelatedData() {
  invalidateUsers()
  invalidateRestricted()
  invalidateEligibleUsers()
}

export function invalidateEligibleUsers() {
  // refetch the eligible users
  queryClient.invalidateQueries(ELIGIBLE_USERS_QUERY_KEY)
}

export function invalidateUsers() {
  queryClient.invalidateQueries(USERS_QUERY_KEY, {
    exact: true,
  })
}

export function invalidateRestricted() {
  // refetch the restricted data
  queryClient.invalidateQueries(RESTRICTED_QUERY_KEY, {
    exact: true,
  })
}

/**
 * @param {import('../hooks/react-query').UseInfiniteQueryOptions<number, FetchError, UsersInfoPerPage} config
 */
export function useTotalEligibleUsersCount(config = {}) {
  const {data, ...queryState} = useEligibleUsers(config)

  return {
    ...queryState,
    data: data?.pages?.[0]?.count,
  }
}

/**
 * @param {string} categoryType
 * @param {import('../hooks/react-query').UseQueryOptions<number, FetchError, number} config
 */
export function useEligibleCountByCategoryType(categoryType, config = {}) {
  return useQuery({
    queryKey: [...ELIGIBLE_USERS_COUNT_QUERY_KEY, {categoryType}],
    queryFn: (_context, fetchOption) =>
      getEligibleCountByCategoryType(
        {
          workspace: getWorkspace(),
          categoryType,
        },
        fetchOption,
      ),
    ...config,
  })
}

/**
 * @template TResult, TVariables, TSnapshot
 * @param {UseMutationOptions<TResult, TVariables, TSnapshot} config
 */
export function useUpdateUserStatus(config = {}) {
  const {onSuccess, ...restConfig} = config
  return useMutation(updateUserStatus, {
    ...restConfig,
    errorMessage: "Unable to update status",
    onSuccess: async (_data, updateUserParams, _context) => {
      // console.log("success update user status", updateUserParams)
      const cachedKeyToUpdate =
        updateUserParams.category === CategoryString.workStatus
          ? ELIGIBLE_USERS_QUERY_KEY
          : USERS_QUERY_KEY

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await cancelUserRelatedDataQuery()

      // optimistic update data in query cache
      queryClient.setQueryData(
        cachedKeyToUpdate,
        updateUserStatusInQueryCache(updateUserParams),
      )

      invalidateUserRelatedData()

      onSuccess && onSuccess()
    },
  })
}

/**
 * @param {UpdateUserStatusParam} param0
 * @returns {(cachedUsersInfoPerPage: UsersInfoPerPageCache) => UsersInfoPerPageCache}
 */
const updateUserStatusInQueryCache = ({
  category,
  categoryType,
  user: userId,
}) => cachedUsersInfoPerPage => {
  return {
    pageParams: cachedUsersInfoPerPage.pageParams,
    pages: cachedUsersInfoPerPage.pages.map(usersInfoPerPage => {
      const user = usersInfoPerPage.dataMap[userId]
      if (!user) return usersInfoPerPage
      if (user?.userStatusInfo.category !== category) return usersInfoPerPage

      return {
        ...usersInfoPerPage,
        dataMap: {
          ...usersInfoPerPage.dataMap,
          [userId]: {
            ...user,
            userStatusInfo: {
              ...user.userStatusInfo,
              categoryType,
              from: UpdateUserStatusRole.admin,
              updateTime: Date.now(),
              status: categoryType,
            },
          },
        },
      }
    }),
  }
}

/**
 * @param {import('../hooks/react-query').UseQueryOptions<number, FetchError>} config
 */
export function useRestricted(config = {}) {
  return useQuery({
    queryKey: RESTRICTED_QUERY_KEY,
    queryFn: (_context, fetchOption) => {
      return getRestricted(
        {
          workspace: getWorkspace(),
        },
        fetchOption,
      )
    },
    ...config,
  })
}

/**
 * @param {GetUserStatusParams} param0
 * @param {import('../hooks/react-query').UseQueryOptions<UserRecord, FetchError>} config
 */
export function useUserStatus(
  {user, category = CategoryString.workStatus},
  config = {},
) {
  return useQuery({
    queryKey: [...USER_STATUS_QUERY_KEY, {user, category}],
    queryFn: () => {
      return getUserStatus({
        user,
        category,
      })
    },
    enabled: !!user,
    ...config,
  })
}

/**
 * @param {UserRecord} userRecord
 */
export function setUserStatus(userRecord) {
  queryClient.setQueryData(
    [
      ...USER_STATUS_QUERY_KEY,
      {
        user: userRecord.userInfo.email,
        category:
          userRecord.userStatusInfo?.category || CategoryString.workStatus,
      },
    ],
    userRecord,
  )
}

/**
 * @param {string} identity  email
 * @param {UserRecord} userRecord
 */
export function navigateToCT(identity, userRecord) {
  if (userRecord) setUserStatus(userRecord)
  if (identity) navigate(`/app/employee/ct?identity=${identity}`)
}

/**
 * @typedef {object} UsersInfoPerPageCache
 * @property {any[]} pageParams
 * @property {UsersInfoPerPage[]} pages
 */

/**
 * @typedef {import('../domains/user').UserInfo} User
 * @typedef {import('../domains/user').UserRecord} UserRecord
 * @typedef {import('../domains/user').LastKeyStatus} LastKeyStatus
 * @typedef {import('../domains/user').UsersInfoPerPage} UsersInfoPerPage
 * @typedef {import('../domains/user').UpdateUserStatusParam} UpdateUserStatusParam
 * @typedef {import('../domains/user').GetUsersStatusParams} GetUsersStatusParams
 * @typedef {import('../domains/user').GetUserStatusParams} GetUserStatusParams
 * @typedef {import('../lib/fetch').FetchError} FetchError
 */
