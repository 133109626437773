/**
 * @description the card is used in employee dashboard,
 * it displays the eligible user count number of different categoryType
 */
import * as React from "react"

import {colors} from "../styles/theme"
import {SkeletonDashboardCard} from "../components/skeleton-dashboard-card"

import {useEligibleCountByCategoryType} from "../services/user"
import {isNull} from "../utils/is-null"

const EligibleCountByTypeCard = ({
  lClass = "w-88",
  rClass = "",
  categoryType,
  title,
  color = colors.butterscotch,
}) => {
  const {isLoading, data, isFetching, error} = useEligibleCountByCategoryType(
    categoryType,
    {
      refetchOnMount: false,
    },
  )

  if (isLoading) return <SkeletonDashboardCard />
  if (error && isNull(data)) return null
  return (
    <div
      className={`dashboard-card ${isFetching ? "text-loader-animation" : ""}`}>
      <div className={`left ${lClass}`}>{title}</div>
      <div
        className={`right ${rClass}`}
        style={{
          color,
        }}>
        {data}
      </div>
    </div>
  )
}

export default EligibleCountByTypeCard
