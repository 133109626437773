import authoredFetch from "../aws/amplify-fetch"

import {
  DEFAULT_BASEURL,
  USER_ENDPOINT,
  ELIGIBLE_USER_ENDPOINT,
  INSERT_ENDPOINT,
} from "./endpoints"

import {
  // PRESERVED_USER_PROFILE_KEYS_A,
  // PRESERVED_USER_PROFILE_KEYS_B,
  // PRESERVED_USER_STATUS_KEYS,
  UpdateUserStatusRole,
  USER_STATUS_DEFAULT_VALUE,
} from "../../domains/user"
import {CategoryString} from "../../domains/category"

// const ALL_USER_PRESERVED_KEYS = PRESERVED_USER_STATUS_KEYS.concat(
//   PRESERVED_USER_PROFILE_KEYS_A,
// ).concat(PRESERVED_USER_PROFILE_KEYS_B)

/**
 * the file should be already uploaded to S3
 * @param {{file: File, workspace: string}} param0
 */
export async function importEmployee({file, workspace}) {
  return authoredFetch(INSERT_ENDPOINT, {
    baseURL: DEFAULT_BASEURL,
    method: "POST",
    data: {
      workspace,
      key: file.name,
    },
    retry: 0,
  })
}

/**
 * @typedef {object} InviteParams
 * @property {string} workspace
 * @property {string} userName
 * @property {string} userEmail
 */

/**
 * @param {InviteParams} param0
 */
export async function invite({userName, userEmail, workspace}) {
  return authoredFetch(USER_ENDPOINT, {
    method: "POST",
    baseURL: DEFAULT_BASEURL,
    data: {
      workspace,
      userName,
      userEmail,
    },
  })
}

/**
 * @typedef {object} GetUsersParams
 * @property {string} workspace
 * @property {number=} limit
 * @property {LastKeyStatus} lastKey
 */

/**
 * @param {GetUsersParams} param0
 * @returns {Promise<UsersInfoPerPage>}
 */
export async function getUsers({workspace, limit = 50, lastKey}, fetchOptions) {
  // console.log("get users", lastKey)
  return authoredFetch(USER_ENDPOINT + "/v1/" + workspace, {
    ...fetchOptions,
    baseURL: DEFAULT_BASEURL,
    method: "GET",
    params: {
      limit,
      ...lastKey,
    },
  }).then(response => {
    /** @type {UsersResponseDTO} */
    let {users = [], ...others} = response.data

    const [userIds, usersMap] = normalizeUsersData(
      users,
      CategoryString.eligibilityStatus,
    )

    return {
      ids: userIds,
      dataMap: usersMap,
      ...others,
    }
  })
}

/**
 * @param {UpdateUserStatusParam} param0
 */
export async function updateUserStatus({
  user,
  category,
  categoryType,
  from = UpdateUserStatusRole.admin,
}) {
  return authoredFetch(`${USER_ENDPOINT}/status`, {
    baseURL: DEFAULT_BASEURL,
    method: "PUT",
    data: {
      user,
      from,
      category,
      categoryType,
    },
  })
}

/**
 * @param {Category} category
 * @returns {(user: UserDTO) => UserRecord}
 */
const transformUserInfo = category => user => {
  // lowercase each key of the returned data
  /** @type {User} */
  let userInfo = {}
  Object.keys(user.userInfo).forEach(key => {
    const lowercaseKey = key.toLowerCase()
    userInfo[lowercaseKey] = user.userInfo[key]
  })

  /** @type {UserStatusInfo} */
  const userStatusInfo = {
    status: undefined,
    category,
    categoryType: undefined,
    userId: userInfo.user_id,
    from: undefined,
    updateTime: undefined,
    ...user.userStatusInfo,
  }

  if (!userStatusInfo.status) {
    userStatusInfo.status = USER_STATUS_DEFAULT_VALUE
  }

  return {
    userId: userInfo.user_id,
    userInfo,
    userStatusInfo,
  }
}

/**
 * @param {UsersResponseDTO[]} users
 * @param {Category} category
 * @returns {[string[], {[id: string]: UserRecord}]}
 */
const normalizeUsersData = (users, category) => {
  const mappedUsers = users.map(transformUserInfo(category))
  // normalize users data
  const userIds = mappedUsers.map(user => user.userId)
  const usersMap = {}
  mappedUsers.forEach(user => {
    usersMap[user.userId] = user
  })
  return [userIds, usersMap]
}

/**
 * @param {GetUsersParams} param0
 * @returns {Promise<UsersInfoPerPage>}
 */
export async function getEligibleUsers(
  {workspace, limit = 50, lastKey = {}},
  fetchOptions,
) {
  return authoredFetch(`${ELIGIBLE_USER_ENDPOINT}/${workspace}`, {
    ...fetchOptions,
    baseURL: DEFAULT_BASEURL,
    method: "GET",
    params: {
      limit,
      ...lastKey,
    },
  }).then(response => {
    /** @type {UsersResponseDTO} */
    let {users = [], ...others} = response.data

    const [userIds, usersMap] = normalizeUsersData(
      users,
      CategoryString.workStatus,
    )

    return {
      ids: userIds,
      dataMap: usersMap,
      ...others,
    }
  })
}

/**
 * @param {GetEligibleUsersCountByCategoryTypeParam} param0
 * @returns {Promise<number>}
 */
export async function getEligibleCountByCategoryType(
  {workspace, categoryType},
  fetchOptions,
) {
  return authoredFetch(`${ELIGIBLE_USER_ENDPOINT}/${workspace}`, {
    ...fetchOptions,
    baseURL: DEFAULT_BASEURL,
    method: "GET",
    params: {
      q: "count",
      categoryType,
    },
  }).then(({data}) => data.count)
}

/**
 * @typedef {object} GetRestrictedParams
 * @property {string} workspace
 */

/**
 * @param {GetRestrictedParams} param0
 * @returns {Promise<number>}
 */
export async function getRestricted({workspace}, fetchOptions) {
  const rawResponse = await authoredFetch(
    ELIGIBLE_USER_ENDPOINT + "/restricted/" + workspace,
    {
      ...fetchOptions,
      method: "GET",
      baseURL: DEFAULT_BASEURL,
    },
  )

  return rawResponse.data?.count || 0
}

/**
 * @param {GetUsersStatusParams} param0
 * @returns {Promise<UsersInfoPerPage>}
 */
export async function getUsersStatus({users, category}, fetchOptions) {
  /** @type {import('../../lib/fetch').FetchResponse<UsersResponseDTO>} */
  const response = await authoredFetch(USER_ENDPOINT + "/status", {
    ...fetchOptions,
    baseURL: DEFAULT_BASEURL,
    method: "POST",
    data: {
      category,
      users,
    },
  })
  let {users: usersData = [], count = users.length} = response.data
  const [userIds, usersMap] = normalizeUsersData(
    usersData,
    CategoryString.workStatus,
  )

  return {
    ids: userIds,
    dataMap: usersMap,
    count,
  }
}

/**
 * @param {GetUserStatusParams} param0
 * @returns {Promise<UserRecord>}
 */
export async function getUserStatus({user, category}, fetchOptions) {
  /** @type {import('../../lib/fetch').FetchResponse<UsersResponseDTO>} */
  const response = await authoredFetch(USER_ENDPOINT + "/status", {
    ...fetchOptions,
    baseURL: DEFAULT_BASEURL,
    method: "POST",
    data: {
      category,
      users: [user],
    },
  })
  let {users = []} = response.data

  if (!users.length) {
    return undefined
  }

  return transformUserInfo(category)(users[0])
}

/**
 * @typedef {import('../../domains/user').UserInfo} User
 * @typedef {import('../../domains/user').UserRecord} UserRecord
 * @typedef {import('../../domains/user').UsersInfoPerPage} UsersInfoPerPage
 * @typedef {import('../../domains/user').UserStatusInfo} UserStatusInfo
 * @typedef {import('../../domains/user').LastKeyStatus} LastKeyStatus
 * @typedef {import('../../domains/user').UpdateUserStatusParam} UpdateUserStatusParam
 * @typedef {import('../../domains/user').GetUsersParams} GetUsersParams
 * @typedef {import('../../domains/user').GetUsersStatusParams} GetUsersStatusParams
 * @typedef {import('../../domains/user').GetUserStatusParams} GetUserStatusParams
 * @typedef {import('../../domains/user').GetEligibleUsersCountByCategoryTypeParam} GetEligibleUsersCountByCategoryTypeParam
 * @typedef {import('../../domains/user').UsersResponseDTO } UsersResponseDTO
 * @typedef {import('../../domains/user').UserDTO } UserDTO
 */
/**
 * @typedef {import('../../domains/category').Category} Category
 * @typedef {import('../../domains/category').CategoryTypeSetting} CategoryTypeSetting
 */
